.artist-link {
  grid-column: span 1;
  grid-row: span 1;
  text-decoration: none;
  align-items: center;
  justify-content: center;
  font-family: "Gotham Rounded";
  color: white;
}

.artist-card:hover {
  background-color: #b3b3b3;
}

.artist-card {
  display: flex;
  background-color: black;
  flex-direction: column;
  height: 30dvw;
  align-items: center;
  justify-content: space-around;
  justify-items: flex-start;
  width: 100%;
}

.extra-card {
  width: 100%;
  height: auto;
  padding-top: 5dvb;
  padding-bottom: 3dvb;
}

.backbutton {
  background-color: transparent;
  color: white;
  border: none;
  padding-left: 5dvb;
}

.backbutton:hover {
  color: #1db954;
}

.backdiv {
  display: flex;
  height: 10dvb;
  align-items: flex-end;
}

.deletebutton {
  display: flex;
  width: 100%;
  height: 100%;
  background-color: black;
  font-size: 30px;
  font-family: "Gotham Rounded Bold";
  color: white;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  border-top: 0.1px solid #ffffff;
  padding-top: 1dvb;
  padding-bottom: 1dvb;
}

.deletebutton:hover {
  background-color: #b3b3b3;
  cursor: pointer;
}

.ranked-card {
  display: flex;
  flex-direction: column;
  background-color: black;
  align-items: center;
  justify-content: center;
}

.drag-card {
  flex-direction: row;
  height: 10dvb;
  width: 100%;
  font-family: "Gotham Rounded";
  color: white;
}

.download-image-section {
  aspect-ratio: 9 / 16;
  max-width: 80%;
  max-height: 80%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.save-title {
  font-size: 1.5vh;
  color: #1db954;
  /* margin: 0.67em 0; */
  font-family: "Gotham Rounded Bold";
  background-color: black;
  padding: 0.5vh;
}

.save-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-grow: 1;
  padding: 0.5vh;
  background-color: black;
}

.save-card {
  display: flex;
  background-color: black;
  align-items: center;
  justify-content: space-around;
  justify-items: flex-start;
  flex-direction: row;
  height: 10%;
  width: 100%;
  font-family: "Gotham Rounded";
  color: white;
  font-size: 1vh;
}
.save-card:hover {
  background-color: #b3b3b3;
}

.play-button {
  border: none;
  background: none;
  color: #1db954;
  width: 5dvb;
}

.play-button:hover {
  color: #ffffff;
}

.over-text {
  display: flex;
  width: 80%;
  justify-content: center;
}

.name-text {
  width: 40dvb;
}
.index-text {
  width: 5dvb;
}
.track-text {
  width: 10dvb;
}
.artist-text {
  width: 30dvb;
}
.artist-image {
  max-width: 60%;
  max-height: 60%;
}

.artist-container {
  list-style-type: none;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1dvw;
  padding-inline-start: 10px;
  padding-inline-end: 10px;
}

.rank-btn-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ranked-btn {
  margin-bottom: 2dvb;
}

@media (max-width: 900px) {
  .artist-container {
    grid-template-columns: repeat(2, 1fr);
    gap: 1dvb;
  }

  /* .index-text {
    display:none;
  } */

  .track-text {
    display: none;
  }

  .play-button {
    width: 20dvb;
  }

  .drag-card {
    height: 8dvb;
  }

  .backdiv {
    height: 5dvb;
  }
}
