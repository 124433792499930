.applayout {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #000000;
  position: fixed;
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0;
}

#email{
  font-family: "Gotham Rounded";
  font-size: small;
  padding: 10px;
  color:white;
}

.title {
  font-size: 12px;
  font-family: "Gotham Rounded Bold";
  text-align: center;
}

.header {
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content:flex-start;
  padding-top: 100px;
}

.applogo:hover{
  cursor:pointer;
}

.content {
  background-color: #1b1b1b;
  height: 100%;
  flex: 5;
  overflow-y: scroll;
}

.menu{
  width: 21dvw;
}

.menu-list {
  list-style: none;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 0;
}

.menu-list li {
  /* margin-right: 8px; */
  margin: 30px;
}

.menu-button{
  text-decoration: none;
  color: white;
  justify-content: space-between;
}

.menu-button:hover{
  background-color: #ffffff;
  color: #000000;
}

.logout-li{
  display: flex;
  justify-content: center;
}

.logout-button{
  color:#1DB954;
  border-color: #1DB954;
}

.logout-button:hover {
  background-color: #1DB954;
  color: #1b1b1b;
  cursor:pointer;
}

.loading {
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: #1b1b1b;
  color: aliceblue;
  height: 100vh;
  font-size: 36px;
  font-family: "Gotham Rounded Bold";
}

@media (max-width: 900px) {

.content {
  width: 100%;
}

  .container {
    width: 100%;
  }

  .applayout{
    flex-direction: column;
    overflow-y: scroll;
    position: relative;
    min-height: 100dvb;
  }

  .header {
    width: 100%;
    padding-top:0px;
    padding: 0px;
  }
  .menu {
    display: flex;
    flex-direction: row;
    width: 100%;
  }
  .menu-list {
    width: 100%;

  }

  .menu-list li {
    margin: 0px;
  }

}