#root {
  margin: 0px;
}

.logo {
  width: 30px;
  height: 30px;
  margin: 10px;
}

.applogo {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.app {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #1b1b1b;
  position: fixed;
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0;
}

.btn-primary {
  display: flex;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  background-color: transparent;
  color: #1DB954;
  font-weight: 700;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  border-radius: 9999px;
  border-width: 3px;
  border-color: #1DB954;
  font-family: "Gotham Rounded";
}

.btn-primary:hover {
  background-color: #1DB954;
  color: #1b1b1b;
}

.btn-secondary {
  margin-top: 20px;

  display: flex;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  background-color: transparent;
  color: #B3B3B3;
  font-weight: 700;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  border-radius: 9999px;
  border-width: 3px;
  border-color: #B3B3B3;
  font-family: "Gotham Rounded";
}

.btn-secondary:hover {
  background-color: #ffffff;
  color: #000000;
}